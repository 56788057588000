<template>
    <div class="form-field">
        <label class="title" v-if="label" :for="myid">{{ label }}</label>
        <div class="restrict" v-if="required">
            <div
                v-if="required"
                :class="[
                    'restrict-item',
                    checkRequired ? 'checked-ok' : 'checked-error'
                ]"
            >
                <span v-if="required == 'start'">
                    開始のみ
                </span>
                <span v-if="required == 'end'">
                    終了のみ
                </span>

                必
            </div>
        </div>
        <div class="form-field-input-group" style="white-space:nowrap;">
            <div
                style="display:inline-block;width:18ex;"
                :class="[
                    'op-date-textfield',
                    { 'op-date-textfield-readonly': startReadOnly }
                ]"
            >
                <div class="form-field-input">
                    <span
                        v-if="startReadOnly"
                        class="op-textfield-inner readonly"
                    >
                        {{ innerModel.start | date }}
                    </span>
                    <span v-else>
                        <v-date-picker
                            v-model="innerModel.start"
                            v-bind:attributes="attributes"
                            :min-date="mindate"
                            :max-date="maxdate"
                        >
                            <template
                                v-slot="{
                                    inputValue,
                                    togglePopover,
                                    inputEvents
                                }"
                            >
                                <div>
                                    <button
                                        class="calendarbutton"
                                        @click="
                                            togglePopover({
                                                placement: 'auto-start'
                                            })
                                        "
                                        tabindex="-1"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            width="20px"
                                        >
                                            <path
                                                d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                                            ></path>
                                        </svg>
                                    </button>
                                    <input
                                        type="text"
                                        class="op-textfield-inner"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                        @change="oncheck"
                                        :id="myid + 'Start'"
                                        autocomplete="off"
                                        ref="startdate"
                                    />
                                </div>
                            </template>
                        </v-date-picker>
                        <button
                            type="button"
                            class="op-textfield-clear-button"
                            @click="innerModel.start = null"
                            tabindex="-1"
                        >
                            ×
                        </button>
                    </span>
                </div>
            </div>
            -
            <div
                style="display:inline-block;width:18ex;"
                :class="[
                    'op-date-textfield',
                    { 'op-date-textfield-readonly': endReadOnly }
                ]"
            >
                <div class="form-field-input">
                    <span
                        v-if="endReadOnly"
                        class="op-textfield-inner readonly"
                    >
                        {{ innerModel.end | date }}
                    </span>
                    <span v-else>
                        <v-date-picker
                            v-model="innerModel.end"
                            v-bind:attributes="attributes"
                            :min-date="mindate"
                            :max-date="maxdate"
                        >
                            <template
                                v-slot="{
                                    inputValue,
                                    togglePopover,
                                    inputEvents
                                }"
                            >
                                <div>
                                    <button
                                        class="calendarbutton"
                                        @click="
                                            togglePopover({
                                                placement: 'auto-start'
                                            })
                                        "
                                        tabindex="-1"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            width="20px"
                                        >
                                            <path
                                                d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                                            ></path>
                                        </svg>
                                    </button>
                                    <input
                                        type="text"
                                        class="op-textfield-inner"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                        @change="oncheck"
                                        :id="myid + 'End'"
                                        autocomplete="off"
                                        ref="enddate"
                                    />
                                </div>
                            </template>
                        </v-date-picker>
                        <button
                            type="button"
                            class="op-textfield-clear-button"
                            @click="innerModel.end = null"
                            tabindex="-1"
                        >
                            ×
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DateUtils from "@/DateUtils";

export default {
    name: "OpDateRangeTextField",
    model: {
        prop: "rangeModel",
        event: "input"
    },
    props: {
        rangeModel: Object,
        required: [Boolean, String], // true("both") | false | "start" | "end" | "either" |
        name: String,
        id: String,
        label: String,
        startReadOnly: Boolean,
        endReadOnly: Boolean,
        mindate: null,
        maxdate: null
    },
    data: function() {
        return {
            innerModel: this.initModel(this.rangeModel),
            validflag: false,
            myid: this.id,
            busyflag: false,
            attributes: [
                {
                    key: "today",
                    highlight: { fillMode: "light" },
                    dates: new Date()
                },
                {
                    bar: "red",
                    dates: {
                        weekdays: [1]
                    }
                },
                {
                    bar: "blue",
                    dates: {
                        weekdays: [7]
                    }
                },
                {
                    bar: "red",
                    dates: this.$store.state.bankNonBusinessDays
                }
            ]
        };
    },
    watch: {
        rangeModel: function(value) {
            let m = this.innerModel;
            if (value) {
                if (m.start != value.start || m.end != value.end) {
                    this.innerModel = this.initModel(value);
                }
            } else {
                if (m.start || m.end) {
                    this.innerModel = this.initModel(value);
                }
            }
        },
        validflag: function(value) {
            if (this.name) {
                this.$emit("changed-valid", this.name, value);
            }
        },
        "innerModel.start": function() {
            if (!this.busyflag) {
                this.adjustDate(true);
            }
        },
        "innerModel.end": function() {
            if (!this.busyflag) {
                this.adjustDate(false);
            }
        }
    },
    methods: {
        initModel: function(source) {
            let st = null;
            let ed = null;
            if (source) {
                if (source.start) {
                    st = source.start;
                }
                if (source.end) {
                    ed = source.end;
                }
            }
            return {
                start: st,
                end: ed
            };
        },
        adjustDate: function(isStartDate) {
            this.busyflag = true;
            let st = this.innerModel.start;
            let ed = this.innerModel.end;
            if (st && ed) {
                if (st > ed) {
                    if (isStartDate) {
                        this.innerModel.end = st;
                    } else {
                        this.innerModel.start = ed;
                    }
                }
            }
            this.busyflag = false;
            this.$emit("input", this.innerModel);
        },
        _checkRequired() {
            let ret = true;
            if (this.required !== false) {
                let a = this.innerModel.start != null;
                let b = this.innerModel.end != null;

                if (this.required === true || this.required == "both") {
                    ret = a && b;
                } else if (this.required == "start") {
                    ret = a;
                } else if (this.required == "end") {
                    ret = b;
                } else if (this.required == "either") {
                    ret = a || b;
                }
            }
            this.validflag = ret;
            return ret;
        },
        focus() {
            if (!this.startReadOnly) {
                this.$refs.startdate.focus();
            } else if (!this.endReadOnly) {
                this.$refs.enddate.focus();
            }
        },
        oncheck: function(event) {
            let val = event.target.value;
            let dt = DateUtils.convertToDateConvenience(val);
            if (dt) {
                val = DateUtils.toString8(dt);
                event.target.value = val;
            }
        }
    },
    computed: {
        checkRequired() {
            return this._checkRequired();
        }
    },
    mounted: function() {
        this._checkRequired();
        this.$emit("changed-valid", this.name, this.validflag);
    },
    created: function() {
        if (!this.myid) {
            this.$store.commit("inclementIdseq");
            this.myid = "oppo2020" + this.$store.state.idseq;
        }
    }
};
</script>

<style scoped>
.op-textfield-clear-button {
    z-index: 2;
    position: absolute;
    right: 5px;
    top: 6px;
    border-style: none;
    padding: 2px;
    margin: 3px;
    box-shadow: none;
}
.calendarbutton {
    z-index: 2;
    position: absolute;
    left: 5px;
    top: 5px;
    border-style: none;
    padding: 4px;
    box-shadow: none;
    border-radius: 0;
    fill: #999;
}
.op-textfield-inner {
    padding-left: 32px;
    padding-right: 32px;
    text-align: left;
}
.op-date-textfield-readonly .form-field-input {
    padding-left: 0px;
    padding-right: 0px;
    text-align: left;
}
</style>
