<template>
    <div class="form-field op-multi-selector">
        <div class="title" v-if="label">{{ label }}</div>
        <div class="restrict" v-if="required">
            <div
                v-if="required"
                :class="[
                    'restrict-item',
                    checkRequired ? 'checked-ok' : 'checked-error'
                ]"
            >
                必
            </div>
        </div>
        <div class="items">
            <span
                v-for="(div, ix) in divlist"
                :key="div.divcode"
                :class="[
                    'op-checkbox',
                    {
                        'status-value':
                            divViewType == 'status' || divViewType == 'short'
                    }
                ]"
            >
                <input
                    type="checkbox"
                    :value="div.divcode"
                    @change="inputChange"
                    v-model="dataModel"
                    :name="name"
                    :id="myid + '_' + ix"
                />
                <label :for="myid + '_' + ix">
                    <span v-if="divViewType == 'status'">
                        <span class="statusValue">
                            {{ div.divcode }}
                            <div class="statusName">
                                {{ div.contentlong }}
                            </div>
                        </span>
                    </span>
                    <span v-else-if="divViewType == 'short'">
                        <span class="statusValue">
                            {{ div.contentshort }}
                            <div class="statusName">
                                {{ div.contentlong }}
                            </div>
                        </span>
                    </span>
                    <span v-else>
                        {{ div.contentlong }}
                    </span>
                </label>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "OpDivMultiSelector",
    model: {
        prop: "arrayModel",
        event: "input"
    },
    props: {
        arrayModel: Array,
        divlist: Object,
        name: String,
        required: Boolean,
        label: String,
        divViewType: String
    },
    data: function() {
        return {
            dataModel: this.sanitizeModel(this.arrayModel),
            validflag: false,
            myid: ""
        };
    },
    methods: {
        sanitizeModel: function(ary) {
            let ret = [];
            if (ary) {
                for (const v of ary) {
                    for (const div in this.divlist) {
                        if (v == div) {
                            ret.push(v);
                        }
                    }
                }
            }
            return ret;
        },
        inputChange: function() {
            this.$emit("input", this.dataModel);
        },
        _checkRequired() {
            let ret = !this.required || this.dataModel != null;
            this.validflag = ret;
            return ret;
        }
    },
    watch: {
        validflag: function(value) {
            if (this.name) {
                this.$emit("changed-valid", this.name, value);
            }
        },
        arrayModel: function(val) {
            this.dataModel = this.sanitizeModel(val);
        },
        divlist: function() {
            this.dataModel = this.sanitizeModel(this.arrayModel);
        }
    },
    computed: {
        checkRequired() {
            return this._checkRequired();
        }
    },
    mounted: function() {
        this._checkRequired();
        this.$emit("changed-valid", this.name, this.validflag);
    },
    created: function() {
        if (!this.myid) {
            this.$store.commit("inclementIdseq");
            this.myid = "oppo2020" + this.$store.state.idseq;
        }
    }
};
</script>
