var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-field op-multi-selector"},[(_vm.label)?_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.required)?_c('div',{staticClass:"restrict"},[(_vm.required)?_c('div',{class:[
                'restrict-item',
                _vm.checkRequired ? 'checked-ok' : 'checked-error'
            ]},[_vm._v(" 必 ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"items"},_vm._l((_vm.divlist),function(div,ix){return _c('span',{key:div.divcode,class:[
                'op-checkbox',
                {
                    'status-value':
                        _vm.divViewType == 'status' || _vm.divViewType == 'short'
                }
            ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataModel),expression:"dataModel"}],attrs:{"type":"checkbox","name":_vm.name,"id":_vm.myid + '_' + ix},domProps:{"value":div.divcode,"checked":Array.isArray(_vm.dataModel)?_vm._i(_vm.dataModel,div.divcode)>-1:(_vm.dataModel)},on:{"change":[function($event){var $$a=_vm.dataModel,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=div.divcode,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.dataModel=$$a.concat([$$v]))}else{$$i>-1&&(_vm.dataModel=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.dataModel=$$c}},_vm.inputChange]}}),_c('label',{attrs:{"for":_vm.myid + '_' + ix}},[(_vm.divViewType == 'status')?_c('span',[_c('span',{staticClass:"statusValue"},[_vm._v(" "+_vm._s(div.divcode)+" "),_c('div',{staticClass:"statusName"},[_vm._v(" "+_vm._s(div.contentlong)+" ")])])]):(_vm.divViewType == 'short')?_c('span',[_c('span',{staticClass:"statusValue"},[_vm._v(" "+_vm._s(div.contentshort)+" "),_c('div',{staticClass:"statusName"},[_vm._v(" "+_vm._s(div.contentlong)+" ")])])]):_c('span',[_vm._v(" "+_vm._s(div.contentlong)+" ")])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }