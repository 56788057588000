<template>
    <div class="cashflow">
        <div class="page-header">
            <h1>日繰り表</h1>

            <div>
                <OpDateRangeTextField
                    label="対象期間"
                    v-model.trim="dates"
                    name="dates"
                    required="start"
                    @input="loadlist()"
                />
            </div>
            <button @click="download" class="downloadbutton">
                <IconBase
                    iconName="Excel"
                    width="24"
                    height="24"
                    visibleName
                    v-slot="{ viewbox }"
                    ><IconDownload @viewbox="viewbox"
                /></IconBase>
            </button>
        </div>
        <div class="header2">
            <div class="soposts">
                <!-- 受注ステータス -->
                <OpDivMultiSelector
                    label="受注ステータス"
                    v-model="salesorderStatus"
                    :divlist="sostslist"
                    name="sosts"
                    divViewType="status"
                    @input="loadlist()"
                />
            </div>
            <div class="soposts">
                <!-- 発注ステータス -->
                <OpDivMultiSelector
                    label="発注ステータス"
                    v-model="purchaseorderStatus"
                    :divlist="postslist"
                    name="posts"
                    divViewType="status"
                    @input="loadlist()"
                />
            </div>
        </div>
        <div class="loader" v-if="loading" key="loading">Now loading...</div>
        <transition name="loading" mode="out-in">
            <div class="table-frame" v-if="!loading">
                <table style="margin: 0 auto;">
                    <thead>
                        <tr>
                            <th>日付</th>
                            <th>入金／支払先</th>
                            <th>明細</th>
                            <th colspan="2">現金・預金</th>
                            <th>入金（税抜）</th>
                            <th>支払（税抜）</th>
                            <th>入金（税込）</th>
                            <th>支払（税込）</th>
                        </tr>
                    </thead>

                    <tbody v-for="(d, ix) in dlist" :key="ix">
                        <tr>
                            <td>{{ d.event_date | date }}</td>
                            <td class="customer-name">
                                {{ d.customer_name_long }}
                            </td>
                            <td>{{ getEventDivNote(d) }}</td>
                            <td>{{ getBankName(d) }}</td>
                            <td>{{ getEventDivName(d) }}</td>
                            <td class="amount">
                                {{ d.deposit_amount | amount }}
                            </td>
                            <td class="amount">
                                {{ d.payment_amount | amount }}
                            </td>
                            <td class="amount">
                                {{ d.deposit_amount_intax | amount }}
                            </td>
                            <td class="amount">
                                {{ d.payment_amount_intax | amount }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </transition>
    </div>
</template>

<script>
import OpDateRangeTextField from "@/components/OpDateRangeTextField.vue";
import OpDivMultiSelector from "@/components/OpDivMultiSelector.vue";
import IconBase from "@/components/icons/IconBase.vue";
import IconDownload from "@/components/icons/IconDownload.vue";

export default {
    name: "CashFlow",
    components: {
        OpDateRangeTextField,
        IconBase,
        IconDownload,
        OpDivMultiSelector
    },
    props: {},
    data: function() {
        return {
            dates: { start: new Date(), end: null },
            dlist: [],
            salesorderStatus: ["A", "B"],
            purchaseorderStatus: ["A", "B"],
            sostslist: {},
            postslist: {},
            loading: true
        };
    },
    methods: {
        getDateRangeParam: function() {
            return (
                this.dateTo8Str(this.dates.start) +
                "-" +
                this.dateTo8Str(this.dates.end)
            );
        },
        makeParams: function() {
            let params = {
                period: this.getDateRangeParam()
            };
            if (this.salesorderStatus && this.salesorderStatus.length > 0) {
                params["sosts"] = this.salesorderStatus;
            }
            if (
                this.purchaseorderStatus &&
                this.purchaseorderStatus.length > 0
            ) {
                params["posts"] = this.purchaseorderStatus;
            }

            return params;
        },
        loadlist: function() {
            this.loading = true;
            let params = this.makeParams();

            return this.readDataToArray(this.makeurl("cf", params), list => {
                this.dlist = list;
                this.loading = false;
            });
        },
        getEventDivNote: function(d) {
            let ret = "";
            if (d.event_div == "S") {
                ret = "売掛金回収";
            } else {
                ret = "買掛金支払";
            }
            return ret;
        },
        getEventDivName: function(d) {
            let ret = "";
            if (d.event_div == "S") {
                ret = "入金";
            } else {
                ret = "支払";
            }
            return ret;
        },
        getBankName: function(d) {
            let ret = "";
            if (d.event_div == "S") {
                ret = d.bank_code;
            } else {
                const comp = this.getCompany();
                ret = comp.payment_bank_code;
            }
            let bk = this.getBank(ret);
            if (bk) {
                ret = bk.bank_name_short;
            } else {
                ret = "";
            }
            return ret;
        },
        download: function() {
            let params = this.makeParams();

            let filename = "日繰り表";
            for (const p in params) {
                let v = params[p];
                filename += "_";
                if (v) {
                    if (v instanceof Array) {
                        for (let c of v) {
                            filename += c;
                        }
                    } else {
                        filename += v;
                    }
                }
            }
            filename += ".xlsx";
            this.downloadData(this.makeurl("cf", "xlsx", params), filename);
        },
        filterSopolist: function() {
            let slist = this.divs["sosts"];
            for (let v in slist) {
                if (v != "Z") {
                    this.sostslist[v] = slist[v];
                }
            }
            let plist = this.divs["posts"];
            for (let v in plist) {
                if (v != "Z") {
                    this.postslist[v] = plist[v];
                }
            }
        }
    },
    created: async function() {
        let dd = this.loaddivs("sosts", "posts");
        if (dd instanceof Promise) {
            dd.then(this.filterSopolist);
        } else {
            this.filterSopolist();
        }
        await this.loadbanklist();
        await this.loadlist();
    }
};
</script>

<style scoped>
.cashflow {
    padding-left: 10px;
}
.header2 {
    display: flex;
    justify-items: center;
    justify-content: center;

    width: 100%;
    text-align: center;
    vertical-align: top;
    z-index: 0;
    padding: 0;
}
.table-frame {
    text-align: center;
}
table th {
    text-align: center;
    font-size: 1rem;
    vertical-align: baseline;
    background-color: var(--title-bg-color);
    color: var(--title-text-color);
    /* filter: grayscale(50%) brightness(98%); */
}
table thead tr > * {
    position: sticky;
    top: calc(4rem + 10px);
    /* background-color: #eee; */
    z-index: 1;
}
tbody:nth-child(2n + 1) {
    background: var(--odd-bg-color);
}
.soposts {
    display: inline-block;
    padding: 5px 20px;
}
.controlbox {
    padding: 10px;
}
.downloadbutton {
    margin-top: 18px;
}
.customer-name {
    text-align: left;
}
</style>
